import React from "react"
import { Link } from "gatsby"
import { useStaticImages } from "../hooks/useStaticImages"

// Custom styles, CSS-in-JS, & Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useMediaQuery, Context as ResponsiveContext } from "react-responsive"
import { css } from "@emotion/core"
import styles from "./Footer.module.scss"

const FooterShape = () => (
  <div
    css={css`
      display: block;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: rotate(180deg);
      @media (min-width: 426px) {
        display: none;
      }
    `}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 100"
      preserveAspectRatio="none"
      css={css`
        display: block;
        position: relative;
        width: calc(130% + 1.3px);
        height: 125px;
        left: 50%;
        transform: translateX(-50%);
      `}
    >
      <path
        fill="#ffffff"
        className={styles.shapeFill}
        d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
      ></path>
    </svg>
  </div>
)

// React Responsive
const MinWidth768px = ({ children }) => {
  const isMinWidth768px = useMediaQuery({ minWidth: 768 })
  return isMinWidth768px ? children : null
}

export const Footer = () => {
  const { footerBackgroundImage } = useStaticImages()

  return (
    <React.Fragment>
      <Container
        fluid
        className={styles.fluidContainer}
        css={css`
          background-image: url(${footerBackgroundImage.publicURL});
          @media (max-width: 425px) {
            background-color: #316546;
            background-image: unset;
          }
        `}
      >
        <ResponsiveContext.Provider value={{ maxWidth: 425 }}>
          <FooterShape />
        </ResponsiveContext.Provider>
        <Container className={styles.container}>
          <Row className={styles.row}>
            <MinWidth768px>
              <Col className={styles.col}>
                <h3>Company</h3>
                <Link to="/about-us/">About Us</Link>
                <Link to="/faq/">FAQs</Link>
                <Link to="/careers/">Careers</Link>
              </Col>
              <Col className={styles.col}>
                <h3>Services</h3>
                <Link to="/accounting-bookkeeping/">
                  Accounting &amp; Bookkeeping
                </Link>
                <Link to="/payroll/">Payroll</Link>
                <Link to="/transfer-pricing/">Transfer Pricing</Link>
                <Link to="/client-management/">Client Management</Link>
                <Link to="/other-services/">Other Services</Link>
              </Col>
            </MinWidth768px>
            <Col className={styles.col}>
              <h3>Social</h3>
              <a
                href="https://www.facebook.com/accountable.ph/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <a
                href="https://www.linkedin.com/company/accountableph/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </Col>
            <Col className={styles.col}>
              <h3>Let's Talk</h3>
              <Link to="/lets-talk/">Book a Meeting</Link>
              <a href="tel:0323247152">032 324 7152</a>
              <a href="tel:09177137862">+63 917 713 7862</a>
              <a
                href="http://maps.google.com/?q=Centrale%20Building,%20Cebu%20I.T.%20Park,%20Cebu%20City,%20Cebu"
                target="_blank"
                rel="noopener noreferrer"
              >
                Unit 509 Park Centrale Building, Cebu I.T. Park, Cebu City
              </a>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <Row className={styles.rightsRow}>
          <Col className={styles.rightsCol}>
            &copy; 2019-{new Date().getFullYear()} AccountablePH. All rights
            reserved.
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}
